const newBuyTicketObject = () => {
    return {
        eventDate: null,
        selectedPlaceId: null,
        selectedPlace: null,
        selectedCategoryId: null,
        selectedCategory: null,
        selectedEvent: null,
        ticketsQuantity: null,
        ticketsTotalPrice: null,
    };
};

export default {
    namespaced: true,
    state() {
        const buyTicketObject = newBuyTicketObject();
        return {
            buyTicketObject
        }
    },
    getters: {},
    mutations: {
        resetBuyObject(state) {
            state.buyTicketObject = newBuyTicketObject()
        },
        setValue(state, { type, item }) {
            console.log(item);
            switch (type) {
                case 'selectedPlace':
                    state.buyTicketObject.selectedPlaceId = item.id;
                    state.buyTicketObject.selectedPlace = item.value;
                    break;
                case 'selectedCategory':
                    state.buyTicketObject.selectedCategoryId = item.id;
                    state.buyTicketObject.selectedCategory = item.value;
                    break;
                default:
                    break;
            }
        },
        setSelectedEvent(state, event) {
            // Надо сюда передавать просто ID того event который хочет купить 
            console.log(event[0])
            if (event.length > 0) {
                state.buyTicketObject.selectedEvent = event[0]
                state.buyTicketObject.ticketsQuantity = event[0].ticketsQuantity,
                state.buyTicketObject.ticketsTotalPrice = event[0].ticketsTotalPrice
            } else {
                state.buyTicketObject.selectedEvent = null
                state.buyTicketObject.ticketsQuantity = null
                state.buyTicketObject.ticketsTotalPrice = null
            }
        }
    },
    actions: {
        resetBuyObject({ commit }) {
            commit("resetBuyObject")
        },
        setValue({ commit }, { type, item }) {
            commit("setValue", { type, item })
        },
        setSelectedEvent({ commit }, event) {
            commit("setSelectedEvent", event)
        }
    },
};