<template>
    <div
        spinner="loader"
        v-loading.fullscreen.lock="shouldBeLoading"
        element-loading-text="Загрузка..."
        element-loading-background="rgba(0, 0, 0, 0.7)"
    />
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
    name: "LoaderComponent",
    components: {},
    props: {
        shouldBeLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
})
</script>