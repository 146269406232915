import { createRouter, createWebHistory } from 'vue-router';
import MainView from '@/views/Main/MainView.vue';
// import HomeView from '@/views/Main/HomeView.vue';
import store from '@/store';

const routes = [
  {
  path: "/",
  name: "main",
  component: MainView
  },
  {
    path: "/sellTicket",
    name: "Sell Ticket",
    component: () => import("@/views/Tickets/SellTicketView")
  },
  {
    path: "/buyTicket",
    name: "Buy Ticket",
    component: () => import("@/views/Tickets/BuyTicketView")
  },
  {
    path: "/mySells",
    name: "My sells",
    component: () => import("@/views/Tickets/MySellsView")
  },
  {
    path: "/myBuys",
    name: "My buys",
    component: () => import("@/views/Tickets/MyBuysView")
  },
  {
    path: "/404",
    name: "not found",
    component: () => import("@/views/Unauthorized/UnauthorizedView")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  next();
});

export default router;
