<template>
    <el-row>
        <el-col class="flex-centered">
        <img class="bot-gif" src="/bot-gif.gif" height="200" width="300" alt="logo" />
        </el-col>
    </el-row>
    <el-row class="empty-card">
        <el-col>
            <el-text class="empty-card-title"><h1>404</h1></el-text>
        </el-col>
        <el-col>
            <el-text class="empty-card-text"><h1>Страница не найдена</h1></el-text>
        </el-col>
    </el-row>
</template>

<script>

export default {
    "name": "UnauthorizedComponent",
}
</script>