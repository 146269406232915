import axios from "axios";
import config from "@/config";

export default {
    async validate(initData) {
        const headers = {
            "Content-type": "application/json"
        };
    
        const body = JSON.stringify({
            initData: initData
        });
    
        const response = await axios
            .post(config.backendUrl + 'auth/validate', body, { headers: headers });
        return response;
    }
};