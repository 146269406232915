import { createStore } from "vuex";
import sellTicketObject from "@/store/modules/sellTicketObject";
import buyTicketObject from "@/store/modules/buyTicketObject";

const store = createStore({
    state: {
        eventType: 0,
    },
    getters: {},
    mutations: {
        setEventType(state, eventType) {
            state.eventType = eventType
        },
    },
    actions: {
        setEventType({ commit }, setEventType) {
            commit("setEventType", setEventType)
        },
    },
    modules: {
        sellTicketObject,
        buyTicketObject,
    }
});

export default store;