<template>
  <div class="common-layout">
    <el-container direction="vertical">
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
    return {}
  },
};
</script>
