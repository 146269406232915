<template>
    <main>
      <template v-if="!isAuthorized && !isLoading">
        <el-row>
          <el-col>
            <UnauthorizedComponent />
          </el-col>
        </el-row>
      </template>
      <template v-if="isAuthorized && !isLoading">
        <el-row style="margin: 16px 0px;">
          <el-col class="flex-centered">
            <img class="bot-gif" src="/tickets.jpg" height="100" alt="logo" />
          </el-col>
        </el-row>
        <el-row>
          <el-col style="display: flex; justify-content: center; margin-top: 16px;">
            <el-text class="menu-header-title">Кому лишний билетик?</el-text>
          </el-col>
          <el-col style="display: flex; justify-content: center;">
            <el-text class="menu-header">Покупайте и продавайте билеты напрямую</el-text>
          </el-col>
        </el-row>
        <el-row style="margin-top: 64px; padding: 0px 4px;">
          <el-col :span="12">
            <el-row>
              <el-col style="display: flex; justify-content: center; padding: 4px;">
                <el-button class="w-100 menu-button" type="info" @click="goSell">
                  <el-icon class="menu-button-icon"><Top /></el-icon>
                  <el-text class="menu-button-text">Продать</el-text>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col style="display: flex; justify-content: center; padding: 4px;">
                <el-button class="w-100 menu-button" type="info" @click="goBuy">
                  <el-icon class="menu-button-icon"><Bottom /></el-icon>
                  <el-text class="menu-button-text">Купить</el-text>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 4px;">
          <el-col :span="12">
            <el-row>
              <el-col style="display: flex; justify-content: center; padding: 4px;">
                <el-button class="w-100 menu-button" type="info" @click="goMySells">
                  <el-icon class="menu-button-icon"><Sell /></el-icon>
                  <el-text class="menu-button-text">Мои продажи</el-text>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col style="display: flex; justify-content: center; padding: 4px;">
                <el-button class="w-100 menu-button" type="info" @click="goMyBuys">
                  <el-icon class="menu-button-icon"><SoldOut /></el-icon>
                  <el-text class="menu-button-text">Мои покупки</el-text>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
    </main>
    <LoaderComponent :shouldBeLoading="isLoading" />
  </template>
    
  <script>
  import UnauthorizedComponent from '@/components/Unauthorized/UnauthorizedComponent.vue'
  import LoaderComponent from "@/components/Loaders/LoaderComponent.vue"
  import AuthService from '@/services/auth.service'
  
  export default {
    name: "MainView",
    components: {
      UnauthorizedComponent,
      LoaderComponent,
    },
    data() {
      return {
        tg: null,
        isAuthorized: false,
        isLoading: false,
      }
    },
    mounted() {
      localStorage.setItem('dataHash', null)
      this.tg = window.Telegram.WebApp
      this.tg.expand()
      this.validate()
    },
    methods: {
      goSell() {
        this.$router.push('sellTicket')
      },
      goBuy() {
        this.$router.push('buyTicket')
      },
      goMySells() {
        this.$router.push('mySells')
      },
      goMyBuys() {
        this.$router.push('myBuys')
      },
      validate() {
        this.isLoading = true
          AuthService.validate(
              this.tg.initData
          ).then((response) => {
            this.isLoading = false
            if (response.data.errorCode == 0) {
              if (response.data.result[0].isValid) {
                localStorage.setItem('dataHash', response.data.result[0].hash)
                this.isAuthorized = true
              } else {
                localStorage.setItem('dataHash', null)
                this.isAuthorized = false
              }
            } else {
              localStorage.setItem('dataHash', null)
              this.isAuthorized = false
            }
          })
      },
    },
  }
  
  </script>
  